html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    color-scheme: dark;
    #background: #111111;
    scroll-snap-type: y mandatory;
    overflow-x: hidden;
    height: 100%;

    @media only screen and (max-width: 768px) {
        scroll-snap-type: none;
    }
}
.PageWrapper {
    width: 100vw;
    height: 100%;
    box-sizing: border-box;
    transition: background-color 0.5s ease 0s, color 0.25s ease 0s;
}
body {
    overflow: auto;
}