.NavigationButton {
    font-size: 22px;
    height: 32px;
    color: grey;
    transition: all 0.5s ease 0s;
    font-family: "Roboto Mono", monospace;
}
.NavigationButton:hover {
    color: white;
    font-size: 24px;
}
.NavigationButtonSelected {
    color: green !important;
    font-size: 24px;
}
.navBar {
    position: sticky;
    height: calc(-32px + 100vh);
    top: 0px;
}
.navB {
    height: 100%;
    padding-bottom: 34px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    -webkit-box-pack: center;
    justify-content: center;
}
.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 22px;
    padding: 64px 0px;
    position: relative;
    font-weight: 300;
}